import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  style: {"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linejoin":"round","stroke-miterlimit":"2"},
  viewBox: "0 0 462 452"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M52.3 57h461.6v452H52.3z",
      style: {"fill":"#ef1c35"},
      transform: "translate(-52.3 -57)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M200 435.4h-14v-25.7c0-5 0-7.1.6-17.7l.3-5.9h-.5l-1.7 5.7c-3.1 10-4 12.8-5.5 16.9l-9.8 26.7H157l-9.9-26.6c-1.7-4.6-2.4-6.7-5.7-16.9l-1.7-5.6h-.5l.3 5.8c.6 10.5.7 13.2.7 17.6v25.7h-14v-61.3h22.4l8.3 22.9c1.4 4 2.3 6.9 5.9 19h.6l1.4-4.8c2.4-8.4 3-10.6 4.3-14.2l8.1-23h22.9v61.3zm23.5-11.2-3.4 11.1h-14.7l19.4-61.3H246l19.7 61.3h-14.5l-3.5-11.1zm12.1-39.5-9 29.1h18.1zm68.5 7.9c0-7-1.4-8.1-10-8.1s-11.6 1.3-11.6 7.2 1.1 5.5 3.9 6.3q1.3.3 11.2.9c9.8.6 13.2 1.3 16.4 3.3 4 2.6 5.3 6.2 5.3 14.3s-1.1 12-4 14.8c-3.9 3.6-8.7 4.6-21.1 4.6s-20.3-1.5-23.4-6.3c-1.9-2.9-2.6-6.2-2.6-13.8h13.5v1.1c0 3.3.6 5.1 1.9 6.4 1.5 1.2 2.8 1.4 11.1 1.4s7-.3 8.6-1.5c1.3-1 1.9-2.9 1.9-6.2s-.5-4.3-1.7-5.1q-1.5-1.2-12.6-1.9c-10-.6-12.9-1.1-16-2.7-4.8-2.4-6.7-6.9-6.7-15.6s1.6-11.9 5-14.3c4-2.8 8.9-3.7 20.3-3.7s17.6 1.2 20.7 4.9c2.3 2.8 3.4 6.4 3.4 11.7v2.5h-13.5zm37.3 5.5h5.2l18-24.1h17.2l-23.2 30 25.3 31.4h-17.6l-19.7-25.2h-5.3v25.2h-14.2v-61.3h14.2v24.1zm99-24.1v39.7c0 1.7-.3 6.4-.6 8.4-.5 4.8-3.2 8.8-7.4 11-4 2.1-7.7 2.7-18 2.7s-14-.6-17.7-2.3c-6.6-2.9-8.5-7.3-8.5-18.8V374h14.2v40.7c0 7.7 1.7 8.9 12.4 8.9s11.5-1.3 11.5-9.6v-40.1h14.2zm-59.6-29.2V261l-27.6 59.3c-4 8.6-12.6 14.1-22.1 14.1H235c-9.5 0-18.1-5.5-22.1-14.1L185.3 261v83.8h-59V130.6h62l68.6 144.7h52.5L378 130.6h62v214.2z",
      style: {"fill":"#fff","fill-rule":"nonzero"},
      transform: "translate(-52.3 -57)"
    }, null, -1)
  ])))
}
export default { render: render }